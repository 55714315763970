<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Cycle Types - Global Statistics" />
      </v-col>
    </v-row>
    <mex-sperm-spinner v-if="globalStatisticsLoading" spinnerText="Loading statistic data" />
    <template v-else>
      <v-row v-for="(globalStatistic, idx) in globalStatistics" :key="`${idx}-${globalStatistic.name}`" justify="center">
        <v-col cols="10">
          <mex-sheet v-if="checkPermissions(globalStatistic.name)" color="foreground" rounded>
            <v-row>
              <v-col align-self="center" cols="3">
                <mex-p :content="globalStatistic.name" class="mb-0" fontSize="h6" />
              </v-col>
            </v-row>
            <v-row>
              <v-col align-self="center" cols="9">
                <mex-p :content="`(${globalStatistic.description})`" class="mb-0" fontSize="caption" />
              </v-col>
            </v-row>
            <v-row justify="start">
              <mex-sheet color="foreground" rounded>
                <v-row>
                  <v-col cols="6">
                    <v-row justify="start">
                      <v-col align-self="center" cols="auto">
                        <mex-p class="mb-0" content="Selectable fields:" fontSize="overline" />
                      </v-col>
                    </v-row>
                    <v-row justify="start">
                      <v-col align-self="center" cols="auto">
                        <v-switch
                          :label="globalStatistic.selectableFields.filter((x) => x.checked).length === 0 ? 'select all' : 'deselect all'"
                          hide-details
                          @change="selectDeselectAll(globalStatistic.selectableFields, idx)"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        v-for="(selectableField, idx) in globalStatistic.selectableFields"
                        :key="`${idx}-{selectableField.field}`"
                        class="pa-0 pl-1"
                        cols="auto"
                      >
                        <v-checkbox v-model="selectableField.checked" :label="selectableField.field" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="4">
                    <v-row>
                      <v-col>
                        <mex-p content="Date range:" fontSize="overline" />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col align-self="center" class="pr-0" cols="6">
                        <v-menu
                          v-model="startDateMenus[idx]"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="startDates[idx]"
                              v-bind="attrs"
                              v-on="on"
                              dense
                              label="Start Date"
                              outlined
                              readonly
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="startDates[idx]" @input="startDateMenus[idx] = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col align-self="center" class="pl-0" cols="3">
                        <mex-btn icon="mdi-close" iconOnly small @click="startDates[idx] = null" />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col align-self="center" class="pr-0" cols="6">
                        <v-menu
                          v-model="endDateMenus[idx]"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="endDates[idx]" v-bind="attrs" v-on="on" dense label="End Date" outlined readonly></v-text-field>
                          </template>
                          <v-date-picker v-model="endDates[idx]" @input="endDateMenus[idx] = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col align-self="center" class="pl-0" cols="3">
                        <mex-btn icon="mdi-close" iconOnly small @click="endDates[idx] = null" />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="2">
                    <v-row>
                      <v-col>
                        <mex-p content="Download:" fontSize="overline" />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <mex-btn
                          :disabled="globalStatistic.selectableFields.filter((x) => x.checked).length === 0"
                          content=".json"
                          icon="mdi-code-json"
                          width="100%"
                          @click="fetchGlobalStatisticJSON(globalStatistic, idx)"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <mex-btn
                          :disabled="globalStatistic.selectableFields.filter((x) => x.checked).length === 0"
                          content=".xml"
                          icon="mdi-xml"
                          width="100%"
                          @click="fetchGlobalStatisticXML(globalStatistic, idx)"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col cols="12">
                        <mex-btn
                          :disabled="globalStatistic.selectableFields.filter((x) => x.checked).length === 0"
                          content=".csv"
                          icon="mdi-file-excel"
                          width="100%"
                          @click="fetchGlobalStatisticCSV(globalStatistic, idx)"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </mex-sheet>
            </v-row>
            <v-overlay v-if="specificGlobalStatisticsLoading" absolute>
              <mex-sperm-spinner :spinner-text="specificGlobalStatisticsLoadingText" />
            </v-overlay>
          </mex-sheet>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import GlobalStatisticsService from '../../services/globalStatistics.service';
import requiredPermissions from '../../requiredPermissions';

export default {
  name: 'GlobalStatistics',
  components: {},
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    endpointStatistic() {
      return this.getUserPermissions.includes('_Statistics_Endpoints');
    },
    cycleTypesStatistics() {
      return this.getUserPermissions.includes('_Statistics_Cycle Types');
    },
  },
  data() {
    return {
      // visualization
      globalStatisticsLoading: false,
      globalStatisticsLoadingText: 'Loading global statistics',
      specificGlobalStatisticsLoading: false,
      specificGlobalStatisticsLoadingText: 'Download',
      // selection data
      // global statistics
      globalStatistics: null,
      startDates: [],
      startDateMenus: [],
      endDates: [],
      endDateMenus: [],
      selectAll: [],
      hasPermission: false,
    };
  },
  created() {
    this.globalStatisticsLoading = true;
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.statistics, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          GlobalStatisticsService.getGlobalStatistics()
            .then((response) => {
              this.globalStatistics = response.data;
              this.addDateSelectsAndLoadingIndicators();
              this.globalStatisticsLoading = false;
            })
            .catch((err) => {
              this.$toast.error(err.response.data);
              this.globalStatisticsLoading = false;
            });
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    selectDeselectAll(selectableFields, idx) {
      // eslint-disable-next-line no-return-assign
      selectableFields.map((x) => (x.checked = this.selectAll[idx]));
      this.selectAll[idx] = !this.selectAll[idx];
    },
    checkPermissions(name) {
      if (name === 'Global cycle type statistics') {
        return this.cycleTypesStatistics;
      }
      if (name === 'Global endpoints statistics') {
        return this.endpointStatistic;
      }
      return true;
    },
    addDateSelectsAndLoadingIndicators() {
      this.globalStatistics.forEach(() => {
        this.startDates.push(this.$dateFormatter.convertJsonDate(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString()).date);
        this.startDateMenus.push(false);
        this.endDates.push(this.$dateFormatter.convertJsonDate(new Date().toISOString()).date);
        this.endDateMenus.push(false);
        this.selectAll.push(false);
      });
    },
    fetchGlobalStatisticJSON(item, idx) {
      this.fetchGlobalStatistic(item, idx, 'json');
    },
    fetchGlobalStatisticXML(item, idx) {
      this.fetchGlobalStatistic(item, idx, 'xml');
    },
    fetchGlobalStatisticCSV(item, idx) {
      this.fetchGlobalStatistic(item, idx, 'csv');
    },
    fetchGlobalStatistic(resourceData, dateIdx, type) {
      this.specificGlobalStatisticsLoading = true;
      GlobalStatisticsService.getSpecificGlobalStatistics(
        resourceData.resource,
        resourceData.selectableFields,
        this.startDates[dateIdx],
        this.endDates[dateIdx],
        type,
      )
        .then((response) => {
          if (type === 'xml') {
            this.downloadStatistics(response.data, this.generateFileName(resourceData, dateIdx, type), 'text/xml');
          } else if (type === 'csv') {
            this.downloadStatistics(response.data, this.generateFileName(resourceData, dateIdx, type), 'text/csv');
          } else {
            this.downloadStatistics(JSON.stringify(response.data), this.generateFileName(resourceData, dateIdx, type), 'text/json');
          }
          this.specificGlobalStatisticsLoading = false;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.specificGlobalStatisticsLoading = false;
        });
    },
    generateFileName(resourceData, dateIdx, type) {
      if (type === 'xml') {
        return `${resourceData.fileName}_${this.startDates[dateIdx].toString()}_${this.endDates[dateIdx].toString()}.xml`;
      }

      return `${resourceData.fileName}_${this.startDates[dateIdx].toString()}_${this.endDates[dateIdx].toString()}${
        type === 'csv' ? '.csv' : '.json'
      }`;
    },
    downloadStatistics(content, filename, fileType) {
      const blob = new Blob([content], { type: fileType });

      const a = document.createElement('a');
      a.download = filename;
      a.href = URL.createObjectURL(blob);
      a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(function revokeDownloadURL() {
        URL.revokeObjectURL(a.href);
      }, 10000);
    },
  },
};
</script>

<style>
.ct-grids line {
  stroke: whitesmoke !important;
}

.ct-labels span {
  color: steelblue;
}
</style>
