import httpClient from '../httpClient/httpClient';

const GlobalStatisticsService = {
  getGlobalStatistics() {
    return httpClient.get('api/get-global-statistics');
  },
  getSpecificGlobalStatistics(resource, selectableFields, startDate, endDate, type) {
    return httpClient.get(resource, {
      params: {
        selectableFields,
        startDate,
        endDate,
        type,
      },
    });
  },
};

export default GlobalStatisticsService;
